import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { Helmet } from 'react-helmet'

export const KontaktPageTemplate = ({ title, content, contentComponent, image }) => {
    const PageContent = contentComponent || Content
    console.log(image)
    return (
        <div>
            <section className="section hero has-overlay section--gradient background--image background--fixed has-text-white level" style={{
                backgroundImage: `url(${
                    !!image.childImageSharp ? image.childImageSharp.fluid.src : image
                    })`
            }}>
                <div className="container level">
                    <div className="section">
                        <div className="columns">
                            <div className="column ">
                                <h1 className="title has-text-white">{title}<b></b></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section section--gradient">
                <div className="container">
                    <div className="columns">
                        <div className="column is-8 is-offset-2">
                            <div className="section">
                                <h2 className="title is-1">
                                    Anrufen, vorbeiradeln, einen Kaffee trinken oder dabei sein?
                                </h2>
                                <a className="button is-primary" href="mailto:info@radentscheid-bayreuth.de">Schreib uns ne Mail</a>
                                <PageContent className="content" content={content} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

KontaktPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
}

const KontaktPage = ({ data }) => {
    const { markdownRemark: post } = data
    console.log(post)
    return (
        <Layout>
            <KontaktPageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                content={post.html}
                image={post.frontmatter.image}
            />
            <Helmet titleTemplate="%s | Radentscheid Bayreuth">
                <title>{`${post.frontmatter.title}`}</title>
                <meta
                    name="description"
                    content={`${post.frontmatter.description}`}
                />
            </Helmet>
        </Layout>
    )
}

KontaktPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default KontaktPage

export const kontaktPageQuery = graphql`
  query KontaktPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
